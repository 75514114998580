import { ColumnsType } from 'antd/es/table';
import { AntdList, Empty, Table } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { IconStatus } from '../../../IconStatus';

export enum RecordStatus {
    ValidationSuccessful = 'validation_successful',
    AwaitingValidation = 'awaiting_validation',
    ValidationError = 'validation_error',
    ValidationWarning = 'validation_warning',
    ValidationNotice = 'validation_notice',
}

interface ValidationResultsProps {
    data: any[];
    group: string;
}

interface Column {
    category: string;
    code: number;
    comment: string | null;
    criteria: string;
    details: string;
    status: string;
    onFilter: () => void;
}

type Filter = {
    value: string | boolean | number;
    record: any;
};

export const ValidationResults: React.FC<ValidationResultsProps> = ({
    data,
    group,
}) => {
    const t = useTranslate();

    const onChangeRowClassName = ({ status }: { status: string }) => {
        return status === 'validation_error' ? 'verror' : '';
    };

    const columns: ColumnsType<Column> = [
        {
            title: t(`${group}.status`),
            dataIndex: 'status',
            render: (value, row) => value && <IconStatus type={value} />,
            filters: [
                {
                    text: t(`${group}.validation_successful`),
                    value: 'validation_successful',
                },
                {
                    text: t(`${group}.awaiting_validation`),
                    value: 'awaiting_validation',
                },
                {
                    text: t(`${group}.validation_error`),
                    value: 'validation_error',
                },
                {
                    text: t(`${group}.validation_warning`),
                    value: 'validation_warning',
                },
                {
                    text: t(`${group}.validation_notice`),
                    value: 'validation_notice',
                },
            ],
            onFilter: (value: Filter['value'], record: Filter['record']) =>
                record.status.indexOf(value) === 0,
            filterMultiple: false,
        },
        {
            title: t(`${group}.code`),
            dataIndex: 'code',
        },
        {
            title: t(`${group}.category`),
            dataIndex: 'category',
        },
        {
            title: t(`${group}.comment`),
            dataIndex: 'comment',
        },
        {
            title: t(`${group}.criteria`),
            dataIndex: 'criteria',
        },
        {
            title: t(`${group}.details`),
            dataIndex: 'details',
            render: value => {
                return !value || value?.length === 0 ? (
                    <div></div>
                ) : (
                    <AntdList
                        header={null}
                        footer={null}
                        dataSource={value ?? []}
                        renderItem={(item: any) => (
                            <AntdList.Item key={item}>{item}</AntdList.Item>
                        )}
                        size="small"
                    />
                );
            },
            width: 300,
        },
    ];

    if (data?.length === 0 || data === undefined || data === null) {
        return <Empty description={t('applications.titles.no_data')} />;
    }

    return (
        <Table
            columns={columns}
            dataSource={data?.map(({ status, ...rest }) => ({
                status,
                ...rest,
            }))}
            rowClassName={onChangeRowClassName}
            rowKey="code"
        />
    );
};
