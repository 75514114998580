import { useTranslation } from 'react-i18next';
import { useGetLocale, useSetLocale } from '@pankod/refine-core';
import { Menu as AntMenu } from 'antd';
import { Avatar, Button, Dropdown, Space } from '@pankod/refine-antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './LangSwitching.module.scss';

interface ILangSwitchingProps {}

const LangSwitching: React.FC<ILangSwitchingProps> = () => {
    const { i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const currentLocale = locale();

    const menu = (
        <AntMenu
            selectedKeys={currentLocale ? [currentLocale] : []}
            className={styles.langSwitchingMenu}
        >
            {[...(i18n.languages || [])].sort().map((lang: string) => {
                return (
                    <AntMenu.Item
                        key={lang}
                        onClick={() => {
                            changeLanguage(lang);
                            localStorage.setItem('lang', lang);
                        }}
                        icon={
                            <span style={{ marginRight: 8 }}>
                                <Avatar
                                    size={16}
                                    src={`/images/flags/${lang}.svg`}
                                />
                            </span>
                        }
                    >
                        {lang === 'en' ? 'English' : 'Українська'}
                    </AntMenu.Item>
                );
            })}
        </AntMenu>
    );

    return (
        <div className={styles.langSwitching}>
            <Dropdown overlay={menu}>
                <Button type="link">
                    <Space>
                        <Avatar
                            size={16}
                            src={`/images/flags/${currentLocale}.svg`}
                        />
                        {currentLocale === 'en' ? 'English' : 'Українська'}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        </div>
    );
};

export default LangSwitching;
