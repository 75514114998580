import React from 'react';
import Container from '../container';

import styles from './footer.module.scss';

export const Footer: React.FC = ({ children }) => (
    <div className={styles.footer}>
        <Container>{children}</Container>
    </div>
);
