const getRoles = (storageKey: string = 'user') => {
    const user = localStorage.getItem(storageKey) ?? '';
    return user && JSON.parse(user)?.roles;
};

export const containsExpert = () => {
    let roles = getRoles();

    return (
        roles?.length &&
        !!roles
            ?.map((role: string) => role.includes('Expert'))
            ?.filter((exp: boolean) => exp).length
    );
};
