import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import xhr from 'i18next-xhr-backend';

import uk from './locales/uk/common.json';
import en from './locales/en/common.json';

let lang = localStorage.getItem('lang') ?? 'uk';

let options = {
    saveMissing: false,
    lng: lang,
    fallbackLng: ['en', 'uk'],
    fallbacks: true,
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
    resources: {
        en: {
            translation: en,
        },
        uk: {
            translation: uk,
        },
    },
};

i18n.use(xhr).use(initReactI18next).init(options);

export default i18n;
