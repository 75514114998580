import i18n from 'i18next';

export default function hydrateVErrors(exception: any) {
    try {
        let { data, status } = exception?.response;

        if (status === 422) {
            return data?.errors;
        }
    } catch (e) {
        return [];
    }
}

export const validationMessage = (code: number) => {
    if (!code) return null;
    return code === 200
        ? i18n.t('applications.fields.validation_successful')
        : i18n.t('applications.fields.validation_error');
};
