import React from 'react';

import { Form, Button, Space, Select } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, useSelect, InputNumber } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';

import styles from '../../../../../pages/applications/Create.module.scss';
import { ICategory } from '../../../../../interfaces';

interface IngredientFormProps {
    fieldKey: number;
    [key: string]: any;
}

export const IngredientForm: React.FC<IngredientFormProps> = ({ fieldKey }) => {
    const t = useTranslate();

    const { selectProps: internationalNamesProps } = useSelect<ICategory>({
        resource: 'dictionary/international-names',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    const { selectProps: quantityUnitProps } = useSelect<ICategory>({
        resource: 'dictionary/measurement-units',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    return (
        <>
            <Form.List name={[fieldKey, 'activeIngredients']}>
                {(activeIngredients, { add, remove }, { errors, warnings }) => {
                    return (
                        <>
                            {activeIngredients.map((ingredient, index) => {
                                return (
                                    <Space
                                        key={ingredient.key}
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            marginBottom: 8,
                                            width: '100%',
                                        }}
                                        align="baseline"
                                        className={styles.holder}
                                    >
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={5}>
                                                <Form.Item
                                                    {...ingredient}
                                                    name={[
                                                        ingredient.name,
                                                        'name',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        ingredient.fieldKey,
                                                        'name',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.name_active_ingredients'
                                                    )}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={5}>
                                                <Form.Item
                                                    {...ingredient}
                                                    name={[
                                                        ingredient.name,
                                                        'internationalNameId',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        ingredient.fieldKey,
                                                        'internationalNameId',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.inn'
                                                    )}
                                                >
                                                    <Select
                                                        {...internationalNamesProps}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={5}>
                                                <Form.Item
                                                    {...ingredient}
                                                    name={[
                                                        ingredient.name,
                                                        'quantity',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        ingredient.fieldKey,
                                                        'quantity',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.quantity_active_ingredients'
                                                    )}
                                                >
                                                    <InputNumber
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={20} md={5}>
                                                <Form.Item
                                                    {...ingredient}
                                                    name={[
                                                        ingredient.name,
                                                        'quantity_unit_id',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        ingredient.fieldKey,
                                                        'quantity_unit_id',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.units_active_ingredients'
                                                    )}
                                                >
                                                    <Select
                                                        {...quantityUnitProps}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={4} md={4}>
                                                <MinusCircleOutlined
                                                    style={{
                                                        top: 35,
                                                        position: 'relative',
                                                    }}
                                                    onClick={() => {
                                                        remove(ingredient.name);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Space>
                                );
                            })}
                            <Form.Item noStyle>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add();
                                    }}
                                >
                                    <PlusOutlined />{' '}
                                    {t(
                                        'applications.fields.add_active_ingredients'
                                    )}
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    );
                }}
            </Form.List>
        </>
    );
};
