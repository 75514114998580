import React, { useState } from 'react';
import {
    CanAccess,
    ITreeMenu,
    useLogout,
    useRouterContext,
    useTranslate,
    useMenu,
} from '@pankod/refine-core';
import { Grid, Menu as AntMenu } from '@pankod/refine-antd';
import access from '../../../config/access';

import styles from './Menu.module.scss';
import { useTranslation } from 'react-i18next';

export const Menu: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { t } = useTranslation();

    const { mutate: logout } = useLogout();

    const { Link } = useRouterContext();
    const { SubMenu } = AntMenu;

    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const token = localStorage.getItem('auth');

    const breakpoint = Grid.useBreakpoint();

    const user = localStorage.getItem('user')
        ? localStorage.getItem('user')
        : '';

    const roles = user && JSON.parse(user)?.roles;

    const containsAnExpert = roles.length
        ? roles
              ?.map((role: string) => role.includes('Expert'))
              ?.filter((exp: boolean) => exp)
        : [];

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        return tree.map((item: ITreeMenu) => {
            const { icon, label, route, name, children, parentName } = item;

            if (item.key !== 'login') {
                if (containsAnExpert.length && !access.expert.includes(name)) {
                    // eslint-disable-next-line array-callback-return
                    return;
                }

                if (
                    containsAnExpert.length === 0 &&
                    !access.regular.includes(name)
                ) {
                    // eslint-disable-next-line array-callback-return
                    return;
                }
            }

            if (children?.length > 0) {
                return (
                    <SubMenu key={name} title={label}>
                        {renderTreeView(children, selectedKey)}
                    </SubMenu>
                );
            }
            const isSelected = route === selectedKey;

            return (
                <CanAccess
                    key={route}
                    resource={name?.toLowerCase()}
                    action="list"
                >
                    <AntMenu.Item
                        key={selectedKey}
                        style={{
                            fontWeight: isSelected ? 'bold' : 'normal',
                        }}
                    >
                        <Link to={route}>{label}</Link>
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </AntMenu.Item>
                </CanAccess>
            );
        });
    };

    let userMenuItems = token
        ? menuItems
        : [
              {
                  key: 'login',
                  route: '/login',
                  icon: 'login-icon',
                  label: t('pages.login.signin'),
                  children: [],
              },
          ];

    return (
        <AntMenu
            selectedKeys={[]}
            defaultOpenKeys={defaultOpenKeys}
            mode="horizontal"
            onClick={() => {
                if (!breakpoint.lg) {
                    setCollapsed(true);
                }
            }}
            selectable={false}
            className={styles.headerMenu}
        >
            {/* @ts-ignore */}
            {renderTreeView(userMenuItems, selectedKey)}
            {token && (
                <AntMenu.Item key="logout" onClick={() => logout()}>
                    {translate('buttons.logout', 'Logout')}
                </AntMenu.Item>
            )}
        </AntMenu>
    );
};
