import React from 'react';
import routerProvider from '@pankod/refine-react-router-v6';

const AuthApplicationAdd = React.lazy(
    () => import('../components/layout/custom/AuthApplicationAdd')
);

const AuthForm = React.lazy(
    () => import('../components/layout/custom/AuthForm')
);

const AuthHistory = React.lazy(
    () => import('../components/layout/custom/AuthHistory')
);

const AuthClinicaltrialItems = React.lazy(
    () => import('../components/layout/custom/AuthClinicaltrialItems')
);

const AuthAppExternal = React.lazy(
    () => import('../components/layout/custom/AuthApplicationsExternal')
);

const AuthMenuForms = React.lazy(
    () => import('../components/layout/custom/AuthMenuForms')
);

const SpecShow = React.lazy(() => import('../pages/specs/Show'));
const SpecList = React.lazy(() => import('../pages/specs/List'));

const route = {
    ...routerProvider,
    routes: [
        {
            element: <AuthApplicationAdd />,
            path: '/applications/add',
            layout: true,
        },
        {
            element: <AuthForm />,
            path: '/form',
            layout: true,
        },
        {
            element: <AuthHistory />,
            path: '/history',
            layout: true,
        },
        {
            element: <AuthClinicaltrialItems />,
            path: '/clinicaltrial_items',
            layout: true,
        },
        {
            element: <AuthAppExternal />,
            path: '/applications-external',
            layout: true,
        },
        // {
        //     element: <AuthAppExternal />,
        //     path: '/applications_external',
        //     layout: true,
        // },
        {
            element: <AuthMenuForms />,
            path: '/menuforms',
            layout: true,
        },
        {
            element: <SpecShow />,
            path: '/specs/:query',
            layout: true,
        },
        {
            element: <SpecList />,
            path: '/specs',
            layout: true,
        },
        // {
        //     element: <AuthenticatedApplicationsFlow />,
        //     path: '/applications/flow',
        //     layout: true,
        // },
    ],
};

export default route;
