import React, { useMemo, useState } from 'react';

import { Form, Button, Space, Select, notification } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Col, Input, Row, useSelect, InputNumber } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';

import styles from '../../../../../pages/applications/Create.module.scss';
import { ICategory } from '../../../../../interfaces';

interface IContainedItemsProps {
    fieldKey: number;
}

export const ContainedItems: React.FC<IContainedItemsProps> = ({
    fieldKey,
}) => {
    const t = useTranslate();
    const form = Form.useFormInstance();
    const [packType, setPackType] = useState<string>('medication');
    let isMedication = packType === 'medication';
    let isPackaging = packType === 'package';

    const { selectProps: amountUnitIds } = useSelect<ICategory>({
        resource: 'dictionary/measurement-units',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    let compositions = useMemo(
        () => form.getFieldValue('compositions') ?? [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form?.getFieldValue('compositions')]
    );
    let packages = useMemo(
        () => form.getFieldValue('packages') ?? [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form?.getFieldValue('packages')]
    );
    let compositionList = useMemo(
        () => [
            {
                label: 'Препарат',
                options: compositions.map((composition: any) => ({
                    label: composition?.description,
                    value: composition?.number,
                    disabled: isPackaging,
                })),
            },
            {
                label: 'Упаковка',
                options: packages.map((packaging: any) => ({
                    label: packaging?.description,
                    value: packaging?.id,
                    disabled: isMedication,
                })),
            },
        ],
        [compositions, packages, isPackaging, isMedication]
    );
    let packingList = useMemo(
        () => [
            {
                value: 'medication',
                label: 'Препарат',
            },
            {
                value: 'package',
                label: 'Упаковка',
            },
            {
                value: 'other',
                label: 'Інше',
            },
        ],
        []
    );

    return (
        <>
            <Form.List name={[fieldKey, 'containedItems']}>
                {(containedItems, { add, remove }, { errors, warnings }) => {
                    return (
                        <>
                            {containedItems.map((item, index) => {
                                let current = form.getFieldValue([
                                    'packages',
                                    fieldKey,
                                    'containedItems',
                                    item.name,
                                    'type',
                                ]);

                                let hasList =
                                    current === 'medication' ||
                                    current === 'package';

                                return (
                                    <Space
                                        key={item.key}
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            marginBottom: 8,
                                            width: '100%',
                                        }}
                                        align="center"
                                        className={styles.holder}
                                    >
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={5}>
                                                <Form.Item
                                                    {...item}
                                                    name={[item.name, 'type']}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        item.fieldKey,
                                                        'type',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.type'
                                                    )}
                                                >
                                                    <Select
                                                        options={packingList}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        onChange={(
                                                            value: string
                                                        ) => {
                                                            setPackType(value);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            {hasList ? (
                                                <>
                                                    <Col xs={24} md={5}>
                                                        <Form.Item
                                                            {...item}
                                                            name={[
                                                                item.name,
                                                                'packaging_options',
                                                            ]}
                                                            //@ts-ignore
                                                            fieldKey={[
                                                                //@ts-ignore
                                                                item.fieldKey,
                                                                'packaging_options',
                                                            ]}
                                                            label="&nbsp;"
                                                            key={index}
                                                        >
                                                            <Select
                                                                options={
                                                                    compositionList
                                                                }
                                                                onChange={(
                                                                    value: string
                                                                ) => {
                                                                    if (value) {
                                                                        let itemId =
                                                                            fieldKey +
                                                                            1;
                                                                        let samePackaging =
                                                                            itemId ===
                                                                            +value;

                                                                        let payload: any[];

                                                                        if (
                                                                            samePackaging
                                                                        ) {
                                                                            notification.error(
                                                                                {
                                                                                    message: `${t(
                                                                                        'You cannot choose the same package'
                                                                                    )}`,
                                                                                }
                                                                            );

                                                                            let containedItems =
                                                                                form.getFieldValue(
                                                                                    [
                                                                                        'packages',
                                                                                        fieldKey,
                                                                                        'containedItems',
                                                                                    ]
                                                                                );

                                                                            let filtered =
                                                                                containedItems.filter(
                                                                                    (
                                                                                        item: any,
                                                                                        itemIndex: any
                                                                                    ) => {
                                                                                        return (
                                                                                            index !==
                                                                                            itemIndex
                                                                                        );
                                                                                    }
                                                                                );

                                                                            payload =
                                                                                [
                                                                                    {
                                                                                        name: [
                                                                                            'packages',
                                                                                            fieldKey,
                                                                                            'containedItems',
                                                                                        ],
                                                                                        value: filtered,
                                                                                    },
                                                                                ];
                                                                        } else {
                                                                            payload =
                                                                                [
                                                                                    {
                                                                                        name: [
                                                                                            'packages',
                                                                                            fieldKey,
                                                                                            'containedItems',
                                                                                            item.name,
                                                                                            'itemId',
                                                                                        ],
                                                                                        value: value,
                                                                                    },
                                                                                ];
                                                                        }

                                                                        form.setFields(
                                                                            payload
                                                                        );
                                                                    }
                                                                }}
                                                                allowClear={
                                                                    true
                                                                }
                                                            ></Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={24}
                                                        md={5}
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                    >
                                                        <Form.Item
                                                            {...item}
                                                            name={[
                                                                item.name,
                                                                'itemId',
                                                            ]}
                                                            //@ts-ignore
                                                            fieldKey={[
                                                                //@ts-ignore
                                                                item.fieldKey,
                                                                'itemId',
                                                            ]}
                                                            key={index}
                                                            label={t(
                                                                'applications.fields.citems_item_id'
                                                            )}
                                                        >
                                                            <InputNumber
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ) : null}

                                            <Col xs={24} md={5}>
                                                <Form.Item
                                                    {...item}
                                                    name={[
                                                        item.name,
                                                        'itemText',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        item.fieldKey,
                                                        'itemText',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.citems_item_text'
                                                    )}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={20} md={5}>
                                                <Form.Item
                                                    {...item}
                                                    name={[item.name, 'amount']}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        item.fieldKey,
                                                        'amount',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.citems_amount'
                                                    )}
                                                >
                                                    <InputNumber />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={5}>
                                                <Form.Item
                                                    {...item}
                                                    name={[
                                                        item.name,
                                                        'amountUnitId',
                                                    ]}
                                                    //@ts-ignore
                                                    fieldKey={[
                                                        //@ts-ignore
                                                        item.fieldKey,
                                                        'amountUnitId',
                                                    ]}
                                                    key={index}
                                                    label={t(
                                                        'applications.fields.citems_amount_unit_id'
                                                    )}
                                                >
                                                    <Select
                                                        {...amountUnitIds}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={4} md={4}>
                                                <MinusCircleOutlined
                                                    style={{
                                                        top: 35,
                                                        position: 'relative',
                                                    }}
                                                    onClick={() => {
                                                        remove(item.name);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Space>
                                );
                            })}
                            <Form.Item noStyle>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add();
                                    }}
                                >
                                    <PlusOutlined />{' '}
                                    {t(
                                        'applications.fields.add_contained_items'
                                    )}
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    );
                }}
            </Form.List>
        </>
    );
};
