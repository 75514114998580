import React from 'react';

export const cards = [
    {
        id: 'specification-ectd-ua-module-1',
        title: 'pages.login.specification_ectd_ua_module_1',
        href: '/specs/specification-ectd-ua-module-1',
        body: [
            {
                label: 'version',
                value: '1.0',
            },
            {
                label: 'date_of_specification',
                value: '29.04.2024',
            },
            {
                label: 'effective_date',
                value: '29.04.2024',
            },
            {
                label: 'dtd_version',
                value: '1.0',
            },
            {
                label: 'specification',
                value: (
                    <a href="https://www.dec.gov.ua/speczyfikkacziya-ua-m1-ectd/">
                        link
                    </a>
                ),
            },
            {
                label: 'checksums',
                value: '',
            },
            {
                label: 'ua-regional.dtd',
                value: (
                    <a href="/ua-m1/ua-regional.dtd">
                        2490411d203beb65034aa9808153e633
                    </a>
                ),
            },
            {
                label: 'ua-envelope.mod',
                value: (
                    <a href="/ua-m1/ua-envelope.mod">
                        883578d24b17f7185c0ee177573df22b
                    </a>
                ),
            },
            {
                label: 'ua-leaf.mod',
                value: (
                    <a href="/ua-m1/ua-leaf.mod">
                        03bdb97b4b9ab4108356ac033cbe594a
                    </a>
                ),
            },
            {
                label: 'ua-regional.xsl',
                value: (
                    <a href="/ua-m1/ua-regional.xsl">
                        0e096bc6f89a3912dfb9b09d0356a224
                    </a>
                ),
            },
            {
                label: 'notes_on_the_version',
                value: 'first_release',
            },
        ],
    },
];
