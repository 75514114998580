import React, { useEffect, useMemo, useState } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
    useNavigation,
    useList,
} from '@pankod/refine-core';
import {
    Create,
    Form,
    Input,
    useForm,
    Space,
    SaveButton,
    Col,
    Row,
    AutoComplete,
    Divider,
} from '@pankod/refine-antd';
import { FileUploader, Success } from '../../components/FileUploader';
import {
    assembleString,
    removeObject,
    storeObject,
} from '../../utils/accessory';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';
import { useDebounce } from 'react-use';
import { ISertRequest } from '../../interfaces/app';
import hydrateVErrors from '../../utils/validate';
import styles from './Add.module.scss';

const INPUT_DELAY_MS = 900;
const STORE_KEY = 'add';

export const ApplicationAdd: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const [fileReady, setFileReady] = useState(false);
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [val, setVal] = useState<string>('');
    const [debouncedValue, setDebouncedValue] = useState<string>('');
    const [app, setApp] = useState<any>(null);
    const { replace } = useNavigation();

    useEffect(() => {
        if (!val) {
            setApp(null);
        }
    }, [val]);

    const onAfterAdd = (): void => {
        removeObject(STORE_KEY);
        form.resetFields();
        setFileReady(false);
    };

    const { formProps, saveButtonProps, form } = useForm<any>({
        onMutationSuccess: ({ data }) => {
            let appId: number = data?.data.id;

            appId && onAfterAdd();
        },
        onMutationError: (error, variables) => {
            storeObject(STORE_KEY, error?.config?.data);

            const validationErrors = hydrateVErrors(error);

            let fields: any[] = [];
            // eslint-disable-next-line array-callback-return
            Object.keys(validationErrors).map((key: string) => {
                if (key === 'certificate.number') {
                    fields.push({
                        name: ['certificate', 'name'],
                        errors: [t('The application field is required.')],
                    });
                } else {
                    fields.push({
                        name: [key],
                        errors: [t(`${validationErrors[key]}`)],
                    });
                }
            });

            form.setFields(fields);
        },
        redirect: false,
        resource: 'applications/attach-ectd',
        successNotification: {
            message: t(
                'notifications.the_download_is_complete_and_wait_for_it_to_be_processed_successfully_check_the_status_of_dossier_processing_after_some_time'
            ),
            type: 'success',
        },
    });

    const handleSuccess = (fileId: Success) => {
        if (fileId) {
            form?.setFieldsValue({
                file_id: fileId,
            });

            setFileReady(true);
        } else {
            form?.setFieldsValue({
                file_id: null,
            });

            setFileReady(false);
        }
    };

    const initialState = {
        certificate: {
            name: '',
            reg_date: '',
            number: '',
        },
        change_reason: '',
        change_text: '',
        file_id: '',
    };

    useDebounce(
        () => {
            setDebouncedValue(val);
        },
        INPUT_DELAY_MS,
        [val]
    );

    const { data } = useList<ISertRequest>({
        resource: 'dictionary/certificate-request',
        config: {
            filters: [
                {
                    operator: 'eq',
                    field: 'query',
                    value: debouncedValue,
                },
            ],
        },
    });

    const requestList: any[] | undefined = data?.data;

    useEffect(() => {
        const setFormFields = () => {
            try {
                let formValues: any = localStorage.getItem(STORE_KEY) ?? {};
                let parsedValues = JSON.parse(formValues);
                let objKeys = Object.keys(parsedValues);

                for (let key in objKeys) {
                    let name = objKeys[key];
                    let item = parsedValues[name];

                    if (name === 'file_id') {
                        setFileReady(true);
                    }

                    form.setFieldsValue({
                        [name]: item,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        };

        setFormFields();
    }, [form, fileReady]);

    useMemo(() => {
        if (requestList?.length) {
            const transformed = requestList?.map((item: ISertRequest) => {
                let concated = assembleString(
                    [
                        'medication.medicationName',
                        'medication.pharmaceuticalForm',
                        'mohApplicationNumber',
                    ],
                    item
                );

                return {
                    ...item,
                    value: concated,
                    label: concated,
                };
            });

            setOptions(transformed);
        } else {
            setOptions([]);
        }
        // eslint-disable-next-line
    }, [requestList, debouncedValue]);

    const handleSelect = (value: string, option: any) => {
        const { mohApplicationNumber, mohRegistrationDate } = option;

        form?.setFields([
            {
                name: ['certificate', 'reg_date'],
                value: mohRegistrationDate,
            },
            {
                name: ['certificate', 'number'],
                value: mohApplicationNumber?.trim(),
            },
            {
                name: ['certificate', 'name'],
                errors: [],
            },
        ]);
    };

    const handleChange = (value: string, option: any) => {
        setVal(value);

        if (Object.keys(option).length) {
            setApp(option);
        } else {
            setApp(null);
        }
    };

    const onValuesChange = (values: any) => {
        Object.keys(values).forEach(field => {
            const error = form.getFieldError(field);
            if (!error.length) {
                return;
            }

            form.setFields([
                {
                    name: field,
                    errors: [],
                },
            ]);
        });
    };

    const saveButton = {
        ...saveButtonProps,
        disabled: !fileReady,
    };

    const fileLocked = true;

    return (
        <Create
            actionButtons={
                <Space>
                    <SaveButton {...saveButton} />
                </Space>
            }
            pageHeaderProps={{
                title: t('applications.titles.add_ectd'),
                breadcrumb: <TranslatedBreadcrumbs />,
                onBack: () => replace('/applications'),
            }}
        >
            <Form
                {...formProps}
                onValuesChange={onValuesChange}
                layout="vertical"
                initialValues={initialState}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.application')}
                            name={['certificate', 'name']}
                        >
                            <AutoComplete
                                value={val}
                                options={options}
                                onChange={handleChange}
                                onSelect={handleSelect}
                                placeholder={t('applications.fields.search')}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24} hidden>
                        <Form.Item
                            label={t('applications.fields.reg_date')}
                            name={['certificate', 'reg_date']}
                            hidden
                        ></Form.Item>

                        <Form.Item
                            label={t(
                                'applications.fields.registration_certificate_number'
                            )}
                            name={['certificate', 'number']}
                            hidden
                        ></Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.change_reason')}
                            name="change_reason"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.change_text')}
                            name="change_text"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t('applications.fields.file')}
                            name="file_id"
                            className={styles.formItem}
                        >
                            <FileUploader
                                onSuccess={handleSuccess}
                                onRemove={() => {
                                    setFileReady(false);
                                }}
                                accept=".zip"
                                disabled={fileLocked}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {app ? (
                <>
                    <Divider />
                    <Row>
                        <Col xs={24}>
                            <Form layout="vertical" onFinish={() => {}}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={t(
                                                'applications.fields.medication_name'
                                            )}
                                            name="name"
                                        >
                                            <div>
                                                {
                                                    app?.medication
                                                        ?.medicationName
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={t(
                                                'applications.fields.pharmaceutical_form'
                                            )}
                                            name="pharmaceutical_form"
                                        >
                                            <div>
                                                {
                                                    app?.medication
                                                        ?.pharmaceuticalForm
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={t(
                                                'applications.fields.moz_application_number'
                                            )}
                                            name="mohApplicationNumber"
                                        >
                                            <div>
                                                {app?.mohApplicationNumber}
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={t(
                                                'applications.fields.moz_registration_date'
                                            )}
                                            name="mohRegistrationDate"
                                        >
                                            <div>
                                                {app?.mohRegistrationDate}
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    {app?.decRegistrationDat && (
                                        <Col xs={24} md={8}>
                                            <Form.Item
                                                label={t(
                                                    'applications.fields.decRegistrationDate'
                                                )}
                                                name="decRegistrationDate"
                                            >
                                                <div>
                                                    {app?.decRegistrationDate}
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    )}

                                    {app?.packageMohApplicationNumber && (
                                        <Col xs={24} md={8}>
                                            <Form.Item
                                                label={t(
                                                    'applications.fields.package_moh_application_number'
                                                )}
                                                name="packageMohApplicationNumber"
                                            >
                                                <div>
                                                    {
                                                        app?.packageMohApplicationNumber
                                                    }
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    )}

                                    {app?.procedureName && (
                                        <Col xs={24} md={8}>
                                            <Form.Item
                                                label={t(
                                                    'applications.fields.procedure'
                                                )}
                                                name="procedureName"
                                            >
                                                <div>{app?.procedureName}</div>
                                            </Form.Item>
                                        </Col>
                                    )}

                                    {app?.type && (
                                        <Col xs={24} md={8}>
                                            <Form.Item
                                                label={t(
                                                    'applications.fields.type'
                                                )}
                                                name="type"
                                            >
                                                <div>{app?.type}</div>
                                            </Form.Item>
                                        </Col>
                                    )}

                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={t(
                                                'applications.fields.state'
                                            )}
                                            name="state"
                                        >
                                            <div>{app?.state}</div>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={t(
                                                'applications.fields.stateCode'
                                            )}
                                            name="stateCode"
                                        >
                                            <div>{app?.stateCode}</div>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={t(
                                                'applications.fields.dossier'
                                            )}
                                            name="dossierId"
                                        >
                                            <div>{app?.dossierId}</div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </>
            ) : null}
        </Create>
    );
};
