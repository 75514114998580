import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Space,
    Select,
    useSelect,
    DatePicker,
} from '@pankod/refine-antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslate } from '@pankod/refine-core';

import styles from './CertificatesInCountries.module.scss';
import { ICategory } from '../../../../interfaces';
import React, { Fragment } from 'react';

export const CertificatesInCountries: React.FC = () => {
    const t = useTranslate();

    const { selectProps: countriesProps } = useSelect<ICategory>({
        resource: 'dictionary/countries',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    return (
        <Row gutter={[16, 16]}>
            <Col
                xs={24}
                md={24}
                style={{ marginTop: 9, marginBottom: 9 }}
                className="certificates_in_countries"
            >
                <Form.List name="certificates_in_countries">
                    {(fields, { add, remove }, { errors }) => {
                        return (
                            <div>
                                {fields.length ? (
                                    <Divider>
                                        {t(
                                            'applications.fields.certificates_in_countries'
                                        )}
                                    </Divider>
                                ) : null}

                                {fields.map((field, index) => {
                                    return (
                                        <Fragment key={field.key}>
                                            <Space
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8,
                                                    width: '100%',
                                                }}
                                                align="start"
                                                className={styles.holder}
                                            >
                                                <Row gutter={[16, 16]}>
                                                    <Col xs={12} md={5}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'countryId',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.country'
                                                            )}
                                                        >
                                                            <Select
                                                                {...countriesProps}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={12} md={5}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'certificateNum',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.certificate_num'
                                                            )}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={12} md={5}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'certificateDate',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.certificate_date'
                                                            )}
                                                        >
                                                            <DatePicker
                                                                placeholder={t(
                                                                    'applications.fields.select_date'
                                                                )}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={12} md={2}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                top: 35,
                                                                position:
                                                                    'relative',
                                                            }}
                                                            onClick={() => {
                                                                remove(
                                                                    field.name
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Space>
                                        </Fragment>
                                    );
                                })}

                                {fields.length ? (
                                    <Divider style={{ margin: '16px 0' }} />
                                ) : null}

                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add();
                                    }}
                                    block
                                    style={{ marginBottom: 20 }}
                                >
                                    <PlusOutlined />{' '}
                                    {t('applications.fields.add_country')}
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </div>
                        );
                    }}
                </Form.List>
            </Col>
        </Row>
    );
};
