import React from 'react';
import { Tabs } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import cn from 'classnames';

import { LoginForm } from 'components/Entry/LoginForm';
import { FileKey } from 'components/Entry/FileKey';
import { Token } from 'components/Entry/Token';
import LangSwitching from '../LangSwitching';

import { Link } from 'react-router-dom';
import { FileTextOutlined } from '@ant-design/icons';
import styles from './Entry.module.scss';
import VisionMode from '../VisionMode';

export const Entry = () => {
    const { TabPane } = Tabs;
    const t = useTranslate();

    return (
        <>
            <Tabs defaultActiveKey="1">
                <TabPane tab={t('pages.login.password')} key="1">
                    <LoginForm />
                </TabPane>
                <TabPane tab={t('pages.login.file_key')} key="2">
                    <FileKey />
                </TabPane>
                <TabPane tab={t('pages.login.hardware_key')} key="3">
                    <Token />
                </TabPane>
            </Tabs>
            <div className={styles.info}>
                <a
                    href="https://dec.gov.ua/populyarni-pytannya/"
                    className={cn(styles.link, styles.restoreLink)}
                >
                    {t(
                        'pages.login.information_on_granting_or_restoring_access_to_the_system'
                    )}
                </a>
                <div className={styles.contacts}>
                    <div className={styles.item}>
                        <span className={styles.row}>
                            {t(
                                'pages.login.registration_forms_and_applications'
                            )}
                            {':'}
                        </span>
                        <span className={styles.row}>
                            <span className={styles.label}>e-mail:</span>
                            <a
                                href="mailto:eias@dec.gov.ua"
                                className={styles.link}
                            >
                                eias@dec.gov.ua
                            </a>
                        </span>
                        <span className={styles.row}>
                            <span className={styles.label}>tel: </span>
                            <a href="tel:+38-044-202-17-07">
                                +38-044-202-17-07
                            </a>
                        </span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.row}>
                            {t('pages.login.the_ectd_question')}
                            {':'}
                        </span>
                        <span className={styles.row}>
                            <span className={styles.label}>e-mail:</span>
                            <a
                                href="mailto:ectd@dec.gov.ua"
                                className={styles.link}
                            >
                                ectd@dec.gov.ua
                            </a>
                        </span>
                        <span className={styles.row}>
                            <span className={styles.label}>tel: </span>
                            <a href="tel:+38-044-202-17-00">
                                +38-044-202-17-00 вн. 1410
                            </a>
                        </span>
                    </div>
                </div>
                <span className={cn(styles.row, styles.specModule)}>
                    <Link to="/specs/specification-ectd-ua-module-1">
                        <FileTextOutlined />{' '}
                        {t('pages.login.specification_ectd_ua_module_1')}
                    </Link>
                </span>
            </div>
            <div className={styles.language}>
                <div className={styles.vision}>
                    <VisionMode dropdownPosition={'bottom'} />
                </div>
                <LangSwitching />
            </div>
        </>
    );
};
