import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../config/path';
import { authProvider } from '../authProvider';
import { HttpError } from '@pankod/refine-core';
import i18n from 'i18next';

const api = axios.create({
    baseURL: config.API,
    timeout: 50000,
    headers: {
        'Content-Type': 'applications/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    validateStatus: status => (status >= 200 && status < 300) || status === 415,
});

api.interceptors.request.use(
    function (config: AxiosRequestConfig) {
        const token = localStorage.getItem('auth');

        if (token) {
            config.headers!.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    function (error: object) {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    function (response: AxiosResponse) {
        return response;
    },
    async function (error: any) {
        let statusCode: number = error.response?.status;

        if (statusCode === 401) {
            return authProvider.logout('');
        }

        let dictionaryErrors: any = {
            422: 'Помилка вводу',
            500: 'Системна помилка',
            404: 'Запис не знайдено',
            403: 'Доступ заборонений',
        };

        let message = dictionaryErrors?.[statusCode];
        let exist = i18n.exists(
            `notifications.${error.response?.data?.message}`
        );
        let trans = i18n.t(`notifications.${error.response?.data?.message}`);

        const customError: HttpError = {
            ...error,
            message: exist ? trans : message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    }
);

export { api };
