import React, { useMemo } from 'react';
import { PublicLayout } from '../../components/layout/layout';
import { Show } from '@pankod/refine-antd';
import { TranslatedBreadcrumbs } from '../../components/TranslatedBreadcrumbs';
import { useNavigation } from '@pankod/refine-core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { tabulate } from '../../components/specification/utils/tabulate';
import { cards } from '../../components/specification/data/cards';
import styles from './Show.module.scss';

export interface ISpecification {
    id?: string;
    title?: string;
    href?: string;
    body?: any[];
}

export interface ISpecificationItem {
    label?: string | JSX.Element | undefined;
    value?: string | JSX.Element | undefined;
}

export type Specification = ISpecification | undefined;

const SpecShow = () => {
    const { replace } = useNavigation();
    const { query } = useParams();
    const { t } = useTranslation();

    const found: Specification = useMemo(
        () =>
            cards.find(
                (card: { id: string | undefined }) => card?.id === query
            ),
        [cards, query]
    );

    return (
        <PublicLayout>
            <Show
                pageHeaderProps={{
                    breadcrumb: <TranslatedBreadcrumbs />,
                    extra: null,
                    title: t('specs.specs'),
                    onBack: () => replace('/specs'),
                }}
            >
                <div className={styles.showTable}>
                    <div className={styles.pageHeading}>
                        <span>{t(`${found?.title}`)}</span>
                    </div>
                    <div className={styles.pageContent}>
                        {tabulate(found?.body, t)}
                    </div>
                </div>
            </Show>
        </PublicLayout>
    );
};

export default SpecShow;
