import React from 'react';
import { Row, Col, AntdLayout, Card, Typography } from '@pankod/refine-antd';

import styles from './login.module.scss';
import { Entry } from '../../components/Entry';
import { Trans, useTranslation } from 'react-i18next';

const { Title } = Typography;

export const Login: React.FC = () => {
    const { t } = useTranslation();
    const CardTitle = (
        <>
            <Title level={3} className={styles.title}>
                <img
                    src="/images/logo.svg"
                    alt={t(
                        'pages.login.state_enterprise_state_expert_center_of_the_ministry_of_health_of_ukraine_description'
                    )}
                    height={90}
                    width={90}
                />
                <span className={styles.titleContent}>
                    <Trans i18nKey="pages.login.state_enterprise_state_expert_center_of_the_ministry_of_health_of_ukraine">
                        State Enterprise State <br />
                        <br /> Ministry of Health of Ukraine
                    </Trans>
                </span>
            </Title>
            <div className={styles.horLine} />
        </>
    );

    return (
        <AntdLayout className={styles.layout}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: '100vh',
                }}
            >
                <Col xs={22}>
                    <div className={styles.container}>
                        <Card
                            title={CardTitle}
                            headStyle={{ borderBottom: 0 }}
                            className={styles.contentBlock}
                        >
                            <Entry />
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
