import React, { useEffect, useState } from 'react';
import { Refine, ResourceProps } from '@pankod/refine-core';
import { ErrorComponent } from '@pankod/refine-antd';
import './styles/antd.less';
import { dataProvider } from 'provider/dataProvider';
import { notificationProvider } from 'provider/notificationProvider';
import { authProvider } from './authProvider';
import {
    Title,
    Header,
    Sider,
    Footer,
    PrivateLayout,
    OffLayoutArea,
} from 'components/layout';
import { useTranslation } from 'react-i18next';
import { Login } from 'pages/login';
import { api } from './utils/api';
import { TusClientProvider } from 'use-tus';
import { VisionProvider } from './context/vision';
import { BetterHelmet } from '@limeyfy/react-seo';
import { usePageName } from './hook/usePageName';
import { useLocation } from 'react-use';
import { ConfigProvider } from 'antd';
import resource from './config/resource';
import route from './config/route';
import uk_UA from 'antd/es/locale/uk_UA';
import en_US from 'antd/es/locale/en_US';

function App() {
    const { t, i18n } = useTranslation();
    const [newResources, setNewResources] = useState<
        ResourceProps[] | undefined
    >([]);

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const { pathname } = useLocation();
    const { pageName } = usePageName(pathname);
    const title = `${t('labels.unified_office_of_the_applicant')}: ${pageName}`;
    let locale = i18nProvider.getLocale();

    useEffect(() => {
        const mapOnResources = () => {
            return resource?.map(resItem => {
                if (resItem.options && resItem.options.label) {
                    let label = resItem.options.label;

                    return {
                        ...resItem,
                        options: {
                            label: t(label),
                        },
                    };
                }

                return resItem;
            });
        };

        let items = mapOnResources();
        if (items) {
            setNewResources(items);
        }
        //eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [resource, t]);

    return (
        <>
            <TusClientProvider>
                <BetterHelmet title={title} subTitle="">
                    <meta name="description" content={pageName} />
                </BetterHelmet>

                <ConfigProvider locale={locale === 'uk' ? uk_UA : en_US}>
                    <VisionProvider>
                        <Refine
                            ReadyPage={() => null}
                            notificationProvider={notificationProvider}
                            catchAll={<ErrorComponent />}
                            routerProvider={route}
                            dataProvider={dataProvider(api)}
                            authProvider={authProvider}
                            LoginPage={Login}
                            resources={newResources}
                            Title={Title}
                            Header={Header}
                            Sider={Sider}
                            Footer={Footer}
                            Layout={PrivateLayout}
                            OffLayoutArea={OffLayoutArea}
                            i18nProvider={i18nProvider}
                        />
                    </VisionProvider>
                </ConfigProvider>
            </TusClientProvider>
        </>
    );
}

export default App;
