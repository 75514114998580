import { createContext, useContext, useEffect, useState } from 'react';
import { MONO_KEY, ZOOM_KEY } from '../constants/vision';

type VisionValue = {
    vision: boolean;
};

interface VisionProviderProps {
    children: React.ReactNode;
}

const VisionContext = createContext<VisionValue>({ vision: false });

export const useVision = () => useContext(VisionContext);

export const VisionProvider: React.FC<VisionProviderProps> = ({ children }) => {
    const [vision, setVision] = useState<VisionValue>({
        vision: false,
    });
    const vMono = localStorage.getItem(MONO_KEY);
    const vZoom = localStorage.getItem(ZOOM_KEY);

    useEffect(() => {
        let vision = !!vMono;
        setVision({ vision });
    }, [vMono, vZoom]);

    return (
        <VisionContext.Provider value={vision}>
            {children}
        </VisionContext.Provider>
    );
};
