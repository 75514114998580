import _get from 'lodash/get';
import dayjs from 'dayjs';

export const assembleString = (
    keys: string[], // ['id', 'name', etc]
    obj: any,
    divider: string = '-'
) => {
    let label = '';

    for (let i = 0; i < keys.length; i++) {
        let item = _get(obj, keys[i]);

        if (item) {
            label += item;

            if (i < keys.length - 1) {
                label += divider;
            }
        }
    }

    return label;
};

export const strToDate = (value: string, separator = ' ') => {
    let dateString = value
        ?.split(separator)?.[0]
        .split('.')
        .reverse()
        .join('-');
    let timeString = value?.split(separator)?.[1];

    return new Date(`${dateString} ${timeString ?? ''}`);
};

export const storeObject = (key: string, data: any) =>
    localStorage.setItem(key, data);

export const removeObject = (key: string) => localStorage.removeItem(key);

export const buildKey = (fieldName: string, num: number) =>
    `${fieldName}-${num}`;

export const splitStr = (str: string, splitter: string) => str?.split(splitter);

export const formatDate = (dateStr: string) => dayjs(dateStr);
