import React from 'react';
import { Breadcrumb } from '@pankod/refine-antd';
import { useBreadcrumb } from '@pankod/refine-core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TranslatedBreadcrumbs = () => {
    const { breadcrumbs } = useBreadcrumb();
    const { t, i18n } = useTranslation();

    const toLowerCase = (str: string): string => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    };

    const translatePhrase = (str: string): string => {
        let text = t(str);

        if (i18n.exists(`labels.${str}`)) {
            text = t(`labels.${str}`);
        }

        return text;
    };

    return (
        <Breadcrumb
            breadcrumbProps={{
                routes: breadcrumbs.map((breadcrumb: any) => {
                    let path = breadcrumb.href;
                    let breadcrumbName = breadcrumb.label;
                    let cyrillicPattern = /[а-яА-ЯЁё]/;
                    let isCyrillic = cyrillicPattern.test(breadcrumbName);

                    if (!isCyrillic) {
                        let name = toLowerCase(breadcrumbName);
                        breadcrumbName = translatePhrase(name);
                    }

                    return {
                        breadcrumbName,
                        path,
                    };
                }),
                itemRender,
            }}
        />
    );
};

export function itemRender(
    route?: any,
    params?: any,
    routes?: any,
    paths?: any
) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
    );
}
