import { CrudFilters, CrudOperators, DataProvider } from '@pankod/refine-core';
import restDataProvider from '@pankod/refine-simple-rest';
import { AxiosInstance } from 'axios';

import config from '../config/path';

type Sort =
    | {
          field: string;
          order: string;
      }
    | undefined;

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case 'eq':
            return '';
        default:
            return '';
    }
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};
    if (filters) {
        // eslint-disable-next-line array-callback-return
        filters.map(filter => {
            if (filter.operator !== 'or') {
                // @ts-ignore
                const { field, operator, value } = filter;

                const mappedOperator = mapOperator(operator);
                queryFilters[`${field}${mappedOperator}`] = value;
            }
        });
    }

    return queryFilters;
};

export const dataProvider = (axios: AxiosInstance): DataProvider => {
    return {
        ...restDataProvider(config.API, axios),
        getList: async ({ resource, pagination, filters, metaData, sort }) => {
            const queryFilters = filters?.length ? generateFilter(filters) : [];
            const url = `${config.API}/api/${resource}`;
            const sortOptions: Sort = sort?.[0];
            const query = {
                page: pagination?.current,
                per_page: pagination?.pageSize,
                sort_field: sortOptions?.field,
                sort_order: sortOptions?.order,
                ...queryFilters,
            };

            const response = await axios.get(`${url}`, {
                params: query,
            });

            return {
                data: response?.data?.data ?? [],
                total: response?.data?.total || 0,
            };
        },
        create: async ({ resource, variables }) => {
            const url = `${config.API}/api/${resource}`;

            const response = await axios.post(url, variables);

            return {
                data: response?.data ?? [],
            };
        },
        getOne: async ({ resource, id, metaData }) => {
            const url = `${config.API}/api/${resource}/${id}`;

            const { data } = await axios.get(url);

            return {
                data: data?.data ?? [],
            };
        },
        update: async ({ resource, id, variables, metaData }) => {
            const url = metaData?.URLSuffix
                ? `${config.API}/${resource}/${id}/${metaData.URLSuffix}`
                : `${config.API}/${resource}/${id}`;

            const { data } = metaData?.URLSuffix
                ? await axios.post(url)
                : await axios.put(url, variables);

            return {
                data,
            };
        },

        deleteOne: async ({ resource, id, variables, metaData }) => {
            const url = metaData?.URLSuffix
                ? `${config.API}/${resource}/${id}/${metaData.URLSuffix}`
                : `${config.API}/${resource}/${id}`;

            const { data } = await axios.delete(url, variables);

            return {
                data,
            };
        },
    };
};
