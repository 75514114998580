import styles from './Container.module.scss';
import { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
    [key: string]: any;
}

const Container: React.FC<IProps> = ({ children }) => (
    <div className={styles.container}>{children}</div>
);

export default Container;
