import React, { Fragment, FC } from 'react';
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space,
    useSelect,
} from '@pankod/refine-antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ContainedItems } from './ContainedItems';
import { useTranslate } from '@pankod/refine-core';

import styles from './Packages.module.scss';
import { ICategory } from '../../../../interfaces';
import { buildKey } from '../../../../utils/accessory';

const FIELD_KEY = 'packages';

export const Packages: FC = () => {
    const t = useTranslate();

    const { selectProps: packageTypes } = useSelect<ICategory>({
        resource: 'dictionary/package_types',
        defaultValue: [],
        optionLabel: 'name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
        metaData: {},
    });

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} md={24} style={{ marginTop: 9, marginBottom: 9 }}>
                <Form.List name={FIELD_KEY}>
                    {(fields, { add, remove }, { errors }) => {
                        return (
                            <div>
                                {fields?.length ? (
                                    <Divider>
                                        {t('applications.fields.packaging')}
                                    </Divider>
                                ) : null}

                                {fields.map((field, index) => {
                                    let initValue = index + 1;
                                    let key = buildKey(FIELD_KEY, ++field.key);

                                    return (
                                        <Fragment key={key}>
                                            <Space
                                                style={{
                                                    display: 'flex',
                                                    marginBottom: 8,
                                                    width: '100%',
                                                }}
                                                align="start"
                                                className={styles.holder}
                                            >
                                                <Row gutter={[16, 16]}>
                                                    <Col
                                                        xs={20}
                                                        md={5}
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'id',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.id'
                                                            )}
                                                            initialValue={
                                                                initValue
                                                            }
                                                        >
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={20} md={5}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'typeId',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.package_type_id'
                                                            )}
                                                        >
                                                            <Select
                                                                {...packageTypes}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={20} md={5}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'description',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.description'
                                                            )}
                                                        >
                                                            <Input.TextArea />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={20} md={3}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'isFinal',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.package_is_final'
                                                            )}
                                                            valuePropName="checked"
                                                            initialValue={false}
                                                        >
                                                            <Checkbox />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={20} md={5}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'certificateText',
                                                            ]}
                                                            label={t(
                                                                'applications.fields.package_certificate_text'
                                                            )}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={4} md={4}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                top: 35,
                                                                position:
                                                                    'relative',
                                                            }}
                                                            onClick={() => {
                                                                remove(
                                                                    field.name
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Space>

                                            <Space
                                                align="start"
                                                className={styles.holder}
                                            >
                                                <Form.Item
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <ContainedItems
                                                        fieldKey={field.name}
                                                    />
                                                </Form.Item>
                                            </Space>
                                        </Fragment>
                                    );
                                })}

                                {fields?.length ? (
                                    <Divider style={{ margin: '16px 0' }} />
                                ) : null}

                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add({}, fields?.length);
                                    }}
                                    block
                                    style={{ marginBottom: 20 }}
                                >
                                    <PlusOutlined />{' '}
                                    {t('applications.fields.add_packaging')}
                                </Button>

                                <Form.ErrorList errors={errors} />
                            </div>
                        );
                    }}
                </Form.List>
            </Col>
        </Row>
    );
};
