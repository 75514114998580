import { Empty } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { DataTable } from '../../../DataTable';
import React from 'react';

interface ExpertisesProps {
    data: any[];
    group: string;
}

export const Expertises: React.FC<ExpertisesProps> = ({ data, group }) => {
    const t = useTranslate();

    if (data?.length === 0 || data === undefined || data === null) {
        return <Empty description={t('applications.titles.no_data')} />;
    }

    return (
        <DataTable
            data={data?.map(({ conclusions, ...restProps }) => {
                const { createdOn, doneDateFact, receivedOn, ...props } =
                    restProps;

                return {
                    ...props,
                    createdOn: createdOn?.split(' ')[0],
                    doneDateFact: doneDateFact?.split(' ')[0],
                    receivedOn: receivedOn?.split(' ')[0],
                };
            })}
            group={group}
        />
    );
};
