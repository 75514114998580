import { Empty } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { DataTable } from '../../../DataTable';

interface StateHistoryProps {
    data: any[];
    group: string;
}

export const StateHistory: React.FC<StateHistoryProps> = ({ data, group }) => {
    const t = useTranslate();

    if (data?.length === 0 || data === undefined || data === null) {
        return <Empty description={t('applications.titles.no_data')} />;
    }

    return <DataTable data={data} group="applications.titles" />;
};
