import { AuthProvider } from '@pankod/refine-core';
import { api } from './utils/api';
import i18n from 'i18next';

export const TOKEN_KEY = 'auth';
export const USER_KEY = 'user';

export const authProvider: AuthProvider = {
    login: async (params: any) => {
        try {
            let url = params.user_name
                ? '/api/auth/login'
                : '/api/auth/login-crypto';
            let { data, status } = await api.post(url, params);

            if (status === 200) {
                if (data?.access_token) {
                    localStorage.setItem(TOKEN_KEY, data?.access_token);
                }

                if (data?.user) {
                    localStorage.setItem(USER_KEY, JSON.stringify(data?.user));
                }

                return Promise.resolve();
            }

            return Promise.reject();
        } catch (e: any) {
            return Promise.reject({
                name: i18n.t('notifications.Login Error'),
                message: e?.message,
            });
        }
    },
    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_KEY);
        window.location.href = '/login';

        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            return Promise.resolve();
        }

        return Promise.reject();
    },
    getPermissions: () => {
        return Promise.resolve();
    },
    getUserIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return Promise.reject();
        }

        return Promise.resolve();
    },
};
