import React, { useEffect, useState } from 'react';

import { LayoutProps } from '@pankod/refine-core';
import { AntdLayout, Grid } from '@pankod/refine-antd';
import { Header } from '../../PublicHeader';
import { Footer } from '../../footer';

import styles from '../Layout.module.scss';
import { OffLayoutArea } from '../../offLayoutArea';
import Container from '../../container';
import { getFullYear } from '../../../../utils/date';
import { useTranslation } from 'react-i18next';

export const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [year, setYear] = useState<number | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        let y = getFullYear();
        setYear(y);
    }, []);

    const breakpoint = Grid.useBreakpoint();

    return (
        <AntdLayout className={styles.pageWrapper}>
            <AntdLayout className={styles.innerPageWrapper}>
                <Header />
                <AntdLayout.Content>
                    <div
                        style={{
                            padding: breakpoint.sm ? 24 : 12,
                            minHeight: 360,
                        }}
                    >
                        <Container
                            style={{
                                width: '100%',
                            }}
                        >
                            {children}
                        </Container>
                    </div>
                    <OffLayoutArea />
                </AntdLayout.Content>
                <Footer>
                    © {year} {t('all_rights_reserved')}
                </Footer>
            </AntdLayout>
        </AntdLayout>
    );
};
