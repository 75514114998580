import {
    Button,
    Col,
    Form,
    FormProps,
    Input,
    Row,
    Select,
    useSelect,
} from '@pankod/refine-antd';
import { ICategory } from '../../../interfaces';
import { useTranslate } from '@pankod/refine-core';

import styles from './Filter.module.scss';

export const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const { selectProps: statusesData } = useSelect<ICategory>({
        resource: 'dictionary/application-statuses',
        optionLabel: 'name',
        optionValue: 'id',
        metaData: {},
    });

    const { selectProps: typesData } = useSelect<ICategory>({
        resource: 'dictionary/application-types',
        optionLabel: 'name',
        optionValue: 'id',
        metaData: {},
    });

    const t = useTranslate();

    return (
        <Form layout="vertical" {...formProps} className={styles.filterWrapper}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('applications.fields.query')}
                        name="query"
                    >
                        <Input
                            allowClear
                            placeholder={t('applications.fields.query')}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('applications.fields.procedure')}
                        name="type_id"
                    >
                        <Select
                            {...typesData}
                            allowClear
                            placeholder={t('applications.fields.type')}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('applications.fields.ectd_dossier_status')}
                        name="status_id"
                    >
                        <Select
                            {...statusesData}
                            allowClear
                            placeholder={t(
                                'applications.fields.ectd_dossier_status'
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t('applications.buttons.filter')}
                </Button>
            </Form.Item>
        </Form>
    );
};
